!function (t) {
  function n(n) {
    return 1 === n.length && "string" == typeof n[0] && isNaN(t.parse(n[0])) && (n[0] = n[0].replace(/-/g, "/")), Array.prototype.slice.call(n);
  }
  function e() {
    return this instanceof e ? new (Function.prototype.bind.apply(t, [null].concat(n(arguments))))() : t();
  }
  e.prototype = t.prototype, e.now = t.now, e.UTC = t.UTC, e.parse = function () {
    return t.parse.apply(t, n(arguments));
  }, Date = e;
}(Date);