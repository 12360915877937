import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService, FeaturesService } from '../services';

@Injectable()
export class InternetInterceptor implements HttpInterceptor {
  constructor(private features: FeaturesService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.features.isConnected$.next(window.navigator.onLine);
    return next.handle(request);
  }
}
