import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

/**
 * Добавляет заголовок 'X-Locale' для серверной локализации данных
 */
@Injectable()
export class LocaleInterceptor implements HttpInterceptor {
  constructor(private translate: TranslateService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.translate && this.translate.currentLang) {
      request = request.clone({
        setHeaders: {
          'X-Locale': this.translate.currentLang,
        }
      });
    }
    return next.handle(request);
  }
}
