import 'reflect-metadata';
import 'fix-date';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from '@env/environment';

import * as Sentry from '@sentry/angular';
import versions from './_versions';
import { AppBrowserModule } from './app/app.browser.module';

Sentry.init({
  dsn: environment.sentry_dsn,
  environment: environment.env,
  release: versions.versionLong,
  integrations: [
    Sentry.browserTracingIntegration({}),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.browserProfilingIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: environment.sentrySampleRate ?? 0.0,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});

console.debug('Sentry initialized. Env: ', environment);

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppBrowserModule)
    .catch((err) => Sentry.captureException(err));
});
