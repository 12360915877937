import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { DestroyableComponent } from '@models/destroyable.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DeviceOrientationEnum, PlatformService, ScreenService } from '../../services';

@Component({
  selector: 'app-cookie-notifier',
  templateUrl: './cookie-notifier.component.html',
  styleUrls: ['./cookie-notifier.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookieNotifierComponent extends DestroyableComponent implements OnInit {
  constructor(
    private screenService: ScreenService,
    public deviceService: DeviceDetectorService,
    protected readonly platform: PlatformService,
  ) {
    super(platform);
  }

  ngOnInit() {}

  public screenClasses() {
    const isPortrait = this.screenService.deviceOrientation === DeviceOrientationEnum.portrait;
    return {
      tablet: this.deviceService.isTablet(),
      mobile: this.deviceService.isMobile(),
      desktop: (!this.deviceService.isTablet() && !this.deviceService.isMobile()) || this.deviceService.isDesktop(),
      portrait: !this.deviceService.isDesktop() && isPortrait,
      landscape: !this.deviceService.isDesktop() && !isPortrait,
    };
  }

  public get showCoockieAgreement() {
    if (this.isBrowser) {
      return !localStorage.getItem('cookie-agreement') && !this.isAutotestAgent;
    } else {
      return false;
    }
  }

  public agree() {
    localStorage.setItem('cookie-agreement', 'true');
  }
}
