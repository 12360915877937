import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, Output } from '@angular/core';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawerComponent {
  @Input() opened = false;
  @Input() start = false;
  @Input() end = false;

  @Output() closed = new EventEmitter<boolean>();

  public close() {
    this.closed.emit(true);
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [DrawerComponent],
  exports: [DrawerComponent],
})
export class DrawerModule {}
