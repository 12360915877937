<div class="drawer"
     [class.start]="false"
     [class.end]="true"
     [class.on]="opened">
  <div class="container">
    <div class="header">
      <div>
        <ng-content select="[header]"></ng-content>
      </div>
      <div class="close" (click)="close()"></div>
    </div>

    <div class="content hover-scroll hover-scroll-y">
      <ng-content select="[content]"></ng-content>
    </div>
  </div>
</div>
