import { transition, trigger, useAnimation } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { toastIn, toastOut } from '@core/animations';
import { ContextService, CrispChatService, PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { TranslateModule } from '@ngx-translate/core';
import {
  ErrorNotificationService,
  ShowAdditionalInfo,
  SubscribeError,
  TIMER_DURATION,
} from '@shared/error-notification/error-notification.service';
import { IconComponent } from '@shared/icon/icon.component';
import { User, UserRoleEnum } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { takeUntil } from 'rxjs/operators';

@Component({
  animations: [
    trigger('enterAnimation', [
      transition(':enter', useAnimation(toastIn)),
      transition(':leave', useAnimation(toastOut)),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-error-notification',
  standalone: true,
  imports: [CommonModule, TranslateModule, IconComponent],
  styleUrls: ['./error-notification.component.scss'],
  templateUrl: './error-notification.component.html',
})
export class ErrorNotificationComponent extends ChangableComponent implements OnInit, AfterViewInit {
  public errorsArray: SubscribeError[];
  public me: User;
  public showInfo: ShowAdditionalInfo[] = [];
  public timers = [];

  public constructor(
    public deviceService: DeviceDetectorService,
    protected errorService: ErrorNotificationService,
    protected contextService: ContextService,
    protected crisp: CrispChatService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit() {
    this.contextService.me$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (me) => {
        this.me = me;
        this.detectChanges();
      },
    });
  }

  ngAfterViewInit() {
    this.errorService.error$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (errorsData) => {
        this.errorsArray = errorsData;
        this.startTimers();
        this.detectChanges();
      },
    });
  }

  public get isDeveloper() {
    return this.me.roles.includes(UserRoleEnum.developer) || this.me.roles.includes(UserRoleEnum.tester);
  }

  public startTimers() {
    this.errorsArray.forEach((error) => {
      this.timers[error.id] = this.setTimeout(() => {
        this.closeNotification(error);
      }, TIMER_DURATION * 1000);
    });
  }

  toggleChat(error: SubscribeError) {
    if (this.isDeveloper) {
      if (!this.showInfo[error.id]?.status) {
        this.showDevInfo(error);
      } else {
        this.hideDevInfo(error);
      }
    } else {
      this.crisp.openChat();
      this.crisp.sayToChat('Error code #' + error.sentryErrorId);
    }
  }

  public showDevInfo(error: SubscribeError) {
    this.showInfo[error.id] = {
      status: true,
      info: 'Error code #' + error.sentryErrorId,
    };
    this.detectChanges();
  }

  public hideDevInfo(error?: SubscribeError) {
    if (error) {
      this.showInfo[error.id] = {
        status: false,
        info: null,
      };
    } else {
      this.showInfo = [];
    }
    this.detectChanges();
  }

  public closeNotification(error: SubscribeError) {
    this.errorService.closeError(error);
    this.detectChanges();
  }

  public trackByFn(index: SubscribeError) {
    return index.id;
  }
}
