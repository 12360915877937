import { DOCUMENT, Location } from '@angular/common';
import { Component, Inject, OnInit, NgZone, ViewChild, ViewContainerRef, AfterViewInit } from '@angular/core';
import {
  NavigationCancel,
  Event as NavigationEvent,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { LoadingBarService } from '@core/components/spinners/loading-bar/loading-bar.service';
import { logger } from '@core/helpers/logger';
import {
  MetaService,
  LanguageService,
  AuthService,
  ContextService,
  ConfigService,
  AnalyticsService,
  BillingService,
  CrispChatService,
  ScreenService,
  RequestService,
  PlatformService,
  FeaturesService,
} from '@core/services/';
import { WebsocketService } from '@core/websocket';
import { environment } from '@env/environment';
import { DestroyableComponent } from '@models/destroyable.component';
import { WINDOW } from '@ng-web-apis/common';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import * as ContentAction from '@store/actions/content.actions';
import { loadBillingSetting, loadProfileSettings } from '@store/actions/data.actions';
import * as FeaturingActions from '@store/actions/featuring.actions';
import * as ProfileAction from '@store/actions/profile.actions';
import { setProfile } from '@store/actions/profile.actions';
import * as UserAction from '@store/actions/users.actions';
import { getMyProfile } from '@store/reducers/profile.reducer';
import { User, Language, SocialNetworkEnum, ISocialSignupRequest } from 'lingo2-models';
import { MetaData, NgEventBus } from 'ng-event-bus';
import { DeviceDetectorService } from 'ngx-device-detector';
import { combineLatest, Observable, of, filter, takeUntil, tap } from 'rxjs';
import { catchError, distinctUntilChanged, first, pluck, switchMap } from 'rxjs/operators';
import { ModalService } from './core/services/modal.services';
// import { ServiceWorkerMainComponent } from './core/services/sw/sw-main.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends DestroyableComponent implements OnInit, AfterViewInit {
  @ViewChild('View__Overflow', { read: ViewContainerRef }) view__overflow: ViewContainerRef;

  private inited = false;
  private meId: string;
  private initialNavigationDone = false;

  /*
   */
  public constructor(
    private authService: AuthService,
    private modal_srv: ModalService,

    public errorNotificationService: ErrorNotificationService,
    private billingService: BillingService, // dont remove, must be created
    private analytics: AnalyticsService, // dont remove, must be created
    // Server requirements
    @Inject(WINDOW) private readonly windowRef: Window,
    private languageService: LanguageService,
    private requestService: RequestService,
    private contextService: ContextService,
    private configService: ConfigService,
    private loadingBar: LoadingBarService,
    private deviceService: DeviceDetectorService,
    private router: Router,
    private store: Store,
    @Inject(DOCUMENT) private document: any,
    private crisp: CrispChatService,
    private screenService: ScreenService,
    private location: Location,
    private websocketService: WebsocketService,
    private metaService: MetaService,
    private zone: NgZone,
    private evtbus: NgEventBus,
    // private serviceWorker: ServiceWorkerMainComponent,
    protected readonly platform: PlatformService,
  ) {
    super(platform);
  }

  /* User
   */
  protected get watchMe$(): Observable<User> {
    return this.authService.user$.pipe(
      tap((me) => {
        if (me && this.meId !== me.id) {
          this.meId = me.id;
          this.billingService.setUser(me);
        }
      }),
    );
  }

  /* Language
   */
  protected get watchLanguage$(): Observable<Language> {
    return this.languageService.language$.pipe(
      tap((language) => {
        this.metaService.updateLanguage(language);
        this.store.dispatch(ProfileAction.setLanguages({ languages: [language.code] }));
        this.configService.onLocaleChanged(language.code); // TODO загружает много данных, отрефакторить
      }),
    );
  }

  protected get watchLanguages$(): Observable<Language[]> {
    return this.languageService.languages$.pipe(
      tap((languages) => {
        this.metaService.setLanguages(languages);
      }),
    );
  }

  /* Init #1
   */
  public ngAfterViewInit(): void {
    this.modal_srv.setContainer(this.view__overflow);
  }

  /*
   */
  public ngOnInit(): void {
    combineLatest([this.watchMe$, this.watchLanguage$, this.watchLanguages$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => this.ngOnInitContinue(),
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });

    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (event) => this.updateNavigationLoadingbar(event),
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      },
    });
  }

  /* Init #2
   */
  private ngOnInitContinue() {
    if (this.inited) {
      return;
    }
    this.inited = true;
    this.enableAngularRouting();

    if (!this.isBrowser) {
      return;
    }

    // Todo. Move.
    this.websocketService.onAccountUpdate.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (message) => {
        /** Обновление учётной записи */
        const _user = { id: message.account_id } as User;
        this.authService.refreshUser(_user);
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'SOCKET-ERROR');
      },
    });

    // Todo. Move.
    this.websocketService.onBalanceUpdate
      .pipe(
        /** Обновление финансовых настроек */
        switchMap(() => {
          this.store.dispatch(loadBillingSetting({ force: true }));
          return of(null);
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        error: (error) => {
          this.errorNotificationService.captureError(error, 'SOCKET-ERROR');
        },
      });

    this.websocketService.onVerification.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (message) => {
        /** Обновление статуса верификации профиля */
        this.store
          .select(getMyProfile)
          .pipe(first())
          .pipe(takeUntil(this.destroyed$))
          // eslint-disable-next-line rxjs/no-nested-subscribe
          .subscribe({
            next: (profile) => {
              profile.verification_status = message.verification_status;
              this.store.dispatch(setProfile({ profile }));
            },
            error: (error) => {
              this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
            },
          });
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'SOCKET-ERROR');
      },
    });

    this.patchUiLanguage();

    this.websocketService.onReferenceUpdate.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (update) => {
        /** Перезагрузка изменённого справочника */
        this.configService.onVersionChanged(update.entity, update.version);
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'SOCKET-ERROR');
      },
    });

    // Todo. Move.
    this.websocketService.status$
      .pipe(
        switchMap((isOnline) => {
          if (isOnline) {
            this.store.dispatch(loadProfileSettings());
            return of(null);
          } else {
            return of(null);
          }
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: () => {},
        error: (error) => {
          this.errorNotificationService.captureError(error, 'SOCKET-ERROR');
        },
      });

    this.crisp.init();
  }

  /* Set user default language if not set before
   */
  private patchUiLanguage() {
    combineLatest([
      this.watchMe$.pipe(
        filter((me) => !!me),
        pluck('ui_language'),
        distinctUntilChanged(),
      ),
      this.watchLanguage$,
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: ([my_locale, language]) => {
          if (my_locale === null) {
            this.languageService.changeLanguage(language, false);
          }
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  /*
   */
  private updateNavigationLoadingbar(event: NavigationEvent) {
    if (event instanceof NavigationStart) {
      this.loadingBar.navigationStart(25);
    }
    if (event instanceof NavigationEnd) {
      if (event.url.split('/')[1] !== 'u') {
        document.querySelector('html').scrollTop = 0;
      }
      this.loadingBar.navigationComplete();
    }
    if (event instanceof NavigationCancel) {
      this.loadingBar.navigationStop();
    }
    if (event instanceof NavigationError) {
      this.loadingBar.navigationStop();
    }
  }

  /*
   */
  private enableAngularRouting() {
    if (!this.initialNavigationDone) {
      this.initialNavigationDone = true;
      this.router.initialNavigation();
    }
  }

  public get isLimitedVersion() {
    return FeaturesService.isLimitedVersion;
  }

  public get isNonWidgetPage(): boolean {
    return (
      this.router.url.includes('/profile') ||
      this.router.url.includes('/constructor') ||
      this.router.url.includes('/content/collections/edit')
    );
  }
}
