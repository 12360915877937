import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { OnUiButtonModule } from 'onclass-ui';
import { CookieNotifierComponent } from './cookie-notifier.component';

@NgModule({
  declarations: [CookieNotifierComponent],
  imports: [CommonModule, OnUiButtonModule, TranslateModule],
  exports: [CookieNotifierComponent],
})
export class CookieNotifierModule {}
