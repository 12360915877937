import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlatformService } from '@app/core/services';
import { ChangableComponent } from '@app/models/changable.component';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";

@Component({
  selector: 'app-seo-helper',
  templateUrl: './seo-helper.component.html',
  styleUrls: ['./seo-helper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeoHelperComponent extends ChangableComponent implements OnInit, OnDestroy {
  public active: boolean;
  public visible = false;
  public highlightMeta = true;
  private subscription = new Subscription();

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: object,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit() {
    if (this.isBrowser) {
      this.watchSeoDebug();
    }
  }

  public toggleHtmlView() {
    this.visible = !this.visible;
    this.cdr.markForCheck();
  }

  public toggleSeoClass() {
    this.highlightMeta = !this.highlightMeta;
    this.injectSeoClass();
  }

  private watchSeoDebug() {
    if (localStorage || false) {
      this.active = localStorage.getItem('APP_SEO_DBG') === '1';
      this.injectSeoClass();
    }

    this.route.queryParamMap.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (query) => {
        if (query.has('seo-debug')) {
          this.active = query.get('seo-debug') === '1';
          this.injectSeoClass();

          if (localStorage || false) {
            localStorage.setItem('APP_SEO_DBG', this.active ? '1' : '');
          }
        }
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
      }
    });
  }

  /** включить помощника для отладки SEO */
  private injectSeoClass(): void {
    const body = document.getElementsByTagName('body')[0];
    if (this.active && this.highlightMeta) {
      body.classList.add('seo-debug');
    } else {
      body.classList.remove('seo-debug');
    }
    this.cdr.markForCheck();
  }
}
