import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { logger } from '@app/core/helpers/logger';
import { ContextService } from '@core/services';
import { DestroyableComponent } from '@models/destroyable.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { Observable } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';

// критичное время исполнения запроса
const criticalTimeMs = 1000; // = 1 секунда

/**
 * Замер времени запроса
 */
@Injectable()
export class ProfilerInterceptor extends DestroyableComponent implements HttpInterceptor {
  private debug = false;

  constructor(public errorNotificationService: ErrorNotificationService, private context: ContextService) {
    super();
    this.context.debug$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (debug) => (this.debug = debug),
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
      }
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const started = Date.now();
    let ok: string;

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => (ok = event instanceof HttpResponse ? 'succeed' : ''),
        (error: HttpErrorResponse) => (ok = 'failed'),
      ),
      // Log when response observable either completes or errors
      finalize(() => {
        const elapsed = Date.now() - started;
        const msg = `${request.method} '${request.urlWithParams}' ${ok} in ${elapsed} ms.`;
        if (elapsed > criticalTimeMs && this.debug) {
          logger.warn('[slow query] ' + msg);
        } else {
          // logger.debug(msg);
        }
      }),
    );
  }
}
