import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestService } from '@core/services';
import { ORIGIN_PARAM } from '@models/constants';
import { Observable } from 'rxjs';

@Injectable()
export class CorsPatchInterceptor implements HttpInterceptor {
  constructor(private request: RequestService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({ setParams: { [ORIGIN_PARAM]: this.host } });
    return next.handle(request);
  }

  /** Код домена, с которого отправляется запрос, слегка упрощённый, чтобы было проще преобразовывать в URL */
  protected get host(): string {
    return this.request.host.replace('http://', '').replace('https://', 'https-').replace(':', '-');
  }
}
