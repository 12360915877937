<div class="cookie-notifier"
     *ngIf="showCoockieAgreement"
    [ngClass]="screenClasses()">
  <div class="info-wrap">
    <div class="text" [innerHTML]="'cookie-text' | translate: {
        'terms1': ( '<a href=&quot;/terms&quot; class=&quot;link normal&quot; target=&quot;_blank&quot;>'
        + ('cookie-terms' | translate) + '</a>' )}"></div>
    <div class="action">
      <on-ui-button [state]="'default'"
                    [class]="'transparent'"
                    class="with-border"
                    label="{{'cookie-btn' | translate}}"
                    (click)="agree()">
      </on-ui-button>
    </div>
  </div>
</div>
