import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { logger } from '@core/helpers/logger';
import { AccountService } from '@core/services';
import { WebsocketService } from '@core/websocket';
import { environment } from '@env/environment';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { getMe } from '@store/reducers/profile.reducer';
import { User, UserRoleEnum } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { combineLatest, takeUntil } from 'rxjs';
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";

@Component({
  selector: 'app-last-version',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './last-version.component.html',
  styleUrls: ['./last-version.component.scss'],
})
export class LastVersionComponent extends ChangableComponent implements OnInit {
  public versions = environment.versions;
  public showVersionBadge = false;
  public showVersion = false;
  public isOnline = false;
  public me: User;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private websocketService: WebsocketService,
    protected readonly store: Store,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  ngOnInit() {
    if (this.isServer) {
      logger.debug('LastVersionComponent -> server -> skip version');
      return;
    }
    combineLatest([this.store.select(getMe), this.router.events])
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: ([me, event]) => {
          this.me = me;
          if (event instanceof NavigationEnd) {
            this.showVersionBadge =
              (event.url.includes('me/settings') || !this.deviceService.isMobile()) && this.isDeveloper;
          }
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        }
      });
    this.websocketService.status$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (isOnline) => (this.isOnline = isOnline),
      error: (error) => {
        this.errorNotificationService.captureError(error, 'SOCKET-ERROR');
        this.isOnline = true;
      }
    });
  }

  public toggleVersion() {
    this.showVersion = !this.showVersion;
  }

  public get isDeveloper(): boolean {
    return AccountService.hasRole(this.me, UserRoleEnum.developer);
  }
}
