import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { logger } from '@app/core/helpers/logger';
import { setHttpError } from '@app/store/actions/general.actions';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/browser';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpStatuses, NotFoundError } from '../../lingo2-errors';

@Injectable()
export class Lingo2ErrorsInterceptor implements HttpInterceptor {
  public constructor(private readonly store: Store) {}
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: any) => {
        if (error) {
          // TODO && error.headers.source && error.headers.source.includes('lingo2')) { // сервисы Lingo2
          switch (error.status) {
            case HttpStatuses.NotFoundError:
              logger.log(request.method + ' ' + request.urlWithParams + ' : 404 Page not found', error);
              throw new NotFoundError({ ...error });
            default:
              this.store.dispatch(setHttpError({ error }));
              Sentry.captureException(error);
              break;
          }
        }
        return throwError(error);
      }),
    );
  }
}
