import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { VERSION_HASH_PARAM, VERSION_NUMBER_PARAM } from '@models/constants';
import { Observable } from 'rxjs';

@Injectable()
export class VersionInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setParams: {
        [VERSION_NUMBER_PARAM]: environment.versions.version,
        [VERSION_HASH_PARAM]: environment.versions.gitCommitHash,
      }
    });
    return next.handle(request);
  }
}
