import { DEFAULTS, SETTINGS } from '@angular/fire/compat/remote-config';
import { environment } from '@env/environment';
import { remoteConfigDefaults } from './constants';

export const remoteConfigProviders = [
  { provide: DEFAULTS, useValue: remoteConfigDefaults },
  {
    provide: SETTINGS,
    useFactory: () =>
      environment.production
        ? {
            minimumFetchIntervalMillis: 24 * 60 * 60 * 1_000,
            fetchTimeoutMillis: 60_000,
          }
        : {
            minimumFetchIntervalMillis: 10_000,
            fetchTimeoutMillis: 60_000,
          },
  },
];
