import { HttpClient } from '@angular/common/http';
import { Directive } from '@angular/core';
import { logger } from '@app/core/helpers/logger';
import { environment } from '@env/environment';
import { DestroyableComponent } from '@models/destroyable.component';
import { TranslateLoader } from '@ngx-translate/core';
import { catchError, map, Observable, of, zip } from 'rxjs';
import { PlatformService } from './platform.service';

@Directive()
class TranslationHttpLoader extends DestroyableComponent implements TranslateLoader {
  protected lastLoadedLang: string;

  public constructor(private http: HttpClient, protected readonly platform: PlatformService) {
    super(platform);
  }

  public getTranslation(lang: string): Observable<object> {
    if (this.lastLoadedLang === lang) {
      return;
    }

    this.lastLoadedLang = lang;
    const isInContext = (localStorage || false) && !!localStorage.getItem('APP__INCNTX');
    const _lang = isInContext ? 'fj-FJ' : lang; // в режиме CrowdIn InContext всегда загружается локаль FJ
    let urlPrefix = `${environment.i18n_url}/${_lang}`;
    // TODO получать gitCommitHash не для lingo2-web, а для lingo2-i18n
    let urlPostfix = '?r=' + environment.versions.gitCommitHash;

    const isEnDEv = environment.i18n_dev && _lang === 'en';
    if (isEnDEv) {
      // EN локаль для разработчика раздаётся через
      // https://git.onclass.tech/onclass/lingo2-i18n/-/blob/master/src/index.js
      urlPrefix = environment.i18n_en_url;
      urlPostfix = '?r=' + Math.random().toString(); // чтобы подавить кэширование для разработчика
    }

    const files: string[] = ['web.json'];
    if (this.isBrowser) {
      files.push('forms.json');
    }

    const observables: Array<Observable<object>> = files.map((file) => {
      const url = `${urlPrefix}/${file}${urlPostfix}`;
      return this.http.get<object>(url).pipe(
        catchError((err) => {
          logger.error(`Error loading resource '${url}'`, err);
          return of({});
        }),
      );
    });
    return zip(...observables).pipe(
      map((translations) => {
        let mergedTranslations = {};
        translations.map((translation) => (mergedTranslations = { ...mergedTranslations, ...translation }));
        return mergedTranslations;
      }),
    );
  }
}

export function HttpLoaderFactory(http: HttpClient, platform: PlatformService) {
  return new TranslationHttpLoader(http, platform);
}
